const Application = function () {
  this.initA4();
  this.tuner = new Tuner(this.a4);
  this.notes = new Notes(".notes", this.tuner);
  this.meter = new Meter(".meter");
  this.frequencyBars = new FrequencyBars(".frequency-bars");
  this.update({
    name: "A",
    frequency: this.a4,
    octave: 4,
    value: 69,
    cents: 0,
  });
};

Application.prototype.initA4 = function () {
  this.$a4 = document.querySelector(".a4 span");
  this.a4 = 440;
  this.$a4.innerHTML = this.a4;
};

Application.prototype.start = function () {
  const self = this;

  this.tuner.onNoteDetected = function (note) {
    if (self.notes.isAutoMode) {
      if (self.lastNote === note.name) {
        self.update(note);
      } else {
        self.lastNote = note.name;
      }
    }
  };
  navigator.permissions
    .query({ name: "microphone" })
    .then(function (permissionStatus) {
      if (permissionStatus.state === "granted") {
        // microphone access is already granted, start the tuner
        console.log(permissionStatus.state, "st");
        startTuner();
      } else if (permissionStatus.state === "prompt") {
        // prompt user for microphone access
        console.log(permissionStatus.state, "st1");
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(function (stream) {
            startTuner();
          })
          .catch(function (err) {
            console.log(
              "The following error occurred when trying to access the microphone: " +
                err
            );
          });
      } else {
        // microphone access is denied
        console.log("microphone access is denied.");
      }
    });

  const startTuner = () => {
    if (!self.tuner.analyser || !self.tuner.stream.active) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          self.tuner.init(stream);
          self.frequencyData = new Uint8Array(
            self.tuner.analyser.frequencyBinCount
          );
        })
        .catch(function (err) {
          console.log(
            "The following error occurred when trying to access the microphone: " +
              err
          );
        });
    }
  };
  this.updateFrequencyBars();
};

Application.prototype.updateFrequencyBars = function () {
  if (this.tuner.analyser) {
    this.tuner.analyser.getByteFrequencyData(this.frequencyData);
    this.frequencyBars.update(this.frequencyData);
  }
  requestAnimationFrame(this.updateFrequencyBars.bind(this));
};

Application.prototype.update = function (note) {
  this.notes.update(note);
  this.meter.update((note.cents / 50) * 45);
  const frequencies = {
    E2: 82,
    A2: 110,
    D3: 147,
    G3: 196,
    B3: 247,
    E4: 330,
  };

  const threshold = 5;


  for (const [noteName, frequency] of Object.entries(frequencies)) {
    if (Math.abs(note.frequency - frequency) <= threshold) {
      if (Math.abs(note.frequency - frequency) > 2){document.body.style.backgroundColor = '#FF7F50';}

      if (Math.abs(note.frequency - frequency) <= 2) {
        document.body.style.backgroundColor = '#6fc276';
        const popup = document.createElement('div');
        popup.className = 'popup-message';
        
        const messageText = document.createElement('div');
        messageText.className = 'message-text';
        messageText.textContent = ` ${noteName} string is tuned `;
        
        const tickSymbol = document.createElement('span');
        tickSymbol.className = 'tick-symbol';
        tickSymbol.textContent = '✔';

        messageText.appendChild(tickSymbol);
        popup.appendChild(messageText);
        
        document.body.appendChild(popup);

        setTimeout(() => {
          document.body.style.backgroundColor = '';
          popup.remove();
        }, 400);
      }
      return;
    }
  }

  document.body.style.backgroundColor = '';
};

const app = new Application();
app.start();
